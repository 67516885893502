














































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { HOST } from "../global";

@Component({
  name: "ManagementList",
})
export default class ManagementList extends Vue {
  @Prop() public compData: any | null;
  @Prop() public smController: any | null;
  public HOST: string = HOST;
  public imageLoaded: boolean = false;
  isOpen = false;
  current = 0;
  itemClick(index: any) {
    if (this.current == index) {
      this.current = 0;
      this.isOpen = !this.isOpen;
    } else {
      this.current = index;
      this.isOpen = true;
    }
  }
}
