var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.preloaderLoaded),expression:"preloaderLoaded"}],staticClass:"col-lg-10 col-sm-10 xs-screen-custom2 p-0 offset-sm-3 offset-lg-2"},[_c('div',{staticClass:"industries-container industries-all-content"},[_c('div',{staticClass:"row ind-flex6"},[_c('div',{staticClass:"col-md-4 text-col custom-grid2"},[_c('div',{staticClass:"column-wrapper",attrs:{"id":"text-column"}},[_c('div',{staticClass:"ind-content new-content main"},[_c('div',{ref:"headWrap",staticClass:"header-wrapper"},_vm._l((_vm.pages),function(page,idx){return _c('h2',{key:'h' + idx,class:{
                  active: idx == _vm.controllers.currentPage,
                  after: idx == _vm.controllers.nextAnime,
                  before: idx == _vm.controllers.beforeAnime,
                }},[_vm._v("\n                "+_vm._s(page.title)+"\n              ")])}),0),_c('div',{ref:"textWrap",staticClass:"paragraph-wrapper"},_vm._l((_vm.pages),function(page,idx){return _c('p',{key:'p' + idx,class:{
                  active: idx == _vm.controllers.currentPage,
                  after: idx == _vm.controllers.nextAnime,
                  before: idx == _vm.controllers.beforeAnime,
                }},[_vm._v("\n                "+_vm._s(page.description)+"\n              ")])}),0)])])]),_c('div',{staticClass:"col-md-8 custom-grid3",attrs:{"id":"img-column"}},[_c('div',{ref:"imgWrap",staticClass:"img-wrapper"},[_vm._l((_vm.pages),function(page,idx){return _c('div',{key:'img' + idx,class:{
              'ind-img': true,
              active: idx == _vm.controllers.currentPage,
              after: idx == _vm.controllers.nextAnime,
              before: idx == _vm.controllers.beforeAnime,
            }},[_c('picture',[_c('source',{attrs:{"media":"(max-width: 425px)","srcset":_vm.HOST + page.hero_image.mobile.src}}),_c('source',{attrs:{"media":"(max-width: 1024px)","srcset":_vm.HOST + page.hero_image.tab.src}}),_c('img',{attrs:{"src":_vm.HOST + page.hero_image.original.src,"alt":"Automotive"}})])])}),_c('div',{staticClass:"picture-overlay1"}),_c('div',{staticClass:"picture-overlay2"})],2)]),_vm._m(0)]),_c('div',{staticClass:"row ind-flex1"},[_c('div',{ref:"concerns",staticClass:"sub-company",attrs:{"id":"sub-company"}},_vm._l((_vm.pages),function(page,idnx){return _c('div',{key:'cnrns' + idnx,class:{
            'concerns-wrapper': true,
            active: idnx == _vm.controllers.currentPage,
            after: idnx == _vm.controllers.nextAnime,
            before: idnx == _vm.controllers.beforeAnime,
          }},_vm._l((page.concerns),function(concern,idx){return _c('router-link',{key:idnx + 'cnrs' + idx,class:['concern', 'concern' + idx],attrs:{"to":{
              name: 'industry',
              params: {
                category: page.meta.slug,
                industry: concern.meta.slug,
              },
            }}},[_c('p',[_vm._v(_vm._s(concern.title))])])}),1)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-more"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Scroll For More")]),_c('img',{attrs:{"src":require("../assets/img/scroll-arrow.svg"),"alt":""}})])}]

export { render, staticRenderFns }