import { render, staticRenderFns } from "./FullWidthTextAndImage.vue?vue&type=template&id=5f3fb515&"
import script from "./FullWidthTextAndImage.vue?vue&type=script&lang=ts&"
export * from "./FullWidthTextAndImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "FullWidthTextAndImage.vue"
export default component.exports