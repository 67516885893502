













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { componentDataType } from '../types';
import { TimelineMax, Power2, Power4 } from 'gsap';
import { HOST } from '../global';

@Component({
  name: 'ConcernImageText',
})
export default class ConcernImageAndText extends Vue {
  @Prop() public compData: any | null;
  public HOST: string = HOST;
  public currentEnterTransition: string | null = null;
  public currentLeaveTransition: string | null = null;
  public mounted() {
    this.$nextTick(() => {
      this.anims();
    });
  }

  // transformHostURL(url: any) {
  //   const domainMatch = /(https?:\/\/)?([a-z0-9.-]*)/;
  //   const finalURL = url.replace(domainMatch, '');
  //   if (finalURL.length === 0) {
  //     return '/';
  //   } else {
  //     return finalURL;
  //   }
  // }

  transformHostURL(url: any) {
    try {
      const parsedURL = new URL(url);
      return parsedURL.pathname || '/';
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '/';
    }
  }

  public enterTransitionHandler(el: any, done: any) {
    if (this.currentEnterTransition) {
      (this as any)[this.currentEnterTransition](el, done);
    }
  }

  public leaveTransitionHandler(el: any, done: any) {
    if (this.currentLeaveTransition) {
      (this as any)[this.currentLeaveTransition](el, done);
    }
  }
  public anims(): void {
    var animas = new TimelineMax({});
    animas
      .staggerFromTo(
        '.concern-img',
        0.8,
        {
          autoAlpha: 0,
          opacity: 0,
          scale: 0.5,
          yPercent: 50,
        },
        {
          autoAlpha: 1,
          opacity: 1,
          scale: 1,
          delay: 1.5,
          yPercent: 0,
          ease: Power4.easeOut,
          clearProps: 'all',
        },
        0.08
      )
      .staggerFromTo(
        '.desc-text p',
        0.9,
        {
          autoAlpha: 0,
          opacity: 0,
          yPercent: 10,
        },
        {
          autoAlpha: 1,
          opacity: 1,
          delay: 0.1,
          yPercent: 0,
          ease: Power4.easeOut,
          clearProps: 'all',
        },
        0.08
      );
  }
}
