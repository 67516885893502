import { AbMenuState, RootState, AboutMenuItem } from '../types';
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import { FETCH_ABOUT_MENU } from './actions.names';
import { API_URL } from '../global';
import { SET_ABOUT_MENU, SET_ABOUT_MENU_ERROR } from './mutations.names';
import axios from 'axios';

export const state: AbMenuState = {
  aboutMenu: [],
  aboutMenuError: false,
};

const namespaced: boolean = false;

const getters: GetterTree<AbMenuState, RootState> = {
  aboutMenu(state): AboutMenuItem[] {
    return state.aboutMenu;
  },
};

const actions: ActionTree<AbMenuState, RootState> = {
  [FETCH_ABOUT_MENU]({ commit }): any {
    axios
      .get(`${API_URL}/others/menu/8/`)
      .then(({ data }) => {
        commit(SET_ABOUT_MENU, data);
      })
      .catch((err) => {
        commit(SET_ABOUT_MENU_ERROR);
      });
  },
};

const mutations: MutationTree<AbMenuState> = {
  [SET_ABOUT_MENU](state, payload: AboutMenuItem[]): any {
    state.aboutMenuError = false;
    state.aboutMenu = payload;
  },
  [SET_ABOUT_MENU_ERROR](state): any {
    state.aboutMenu = [];
    state.aboutMenuError = true;
  },
};

export const abmenuData: Module<AbMenuState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
