

































































































































































import { State, Action, Getter, Mutation } from "vuex-class";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { pageDataType } from "../types";
import FullWidthTextAndImage from "../components/FullWidthTextAndImage.vue";
import Video from "../components/Video.vue";
import { HOST, API_URL } from "../global";
import { animeDict } from "../animations";
import { TimelineMax, TimelineLite, Expo, Power2 } from "gsap";
import ScrollMagic from "scrollmagic";
import { ADD_ANIMATION, SET_PAGE_IMAGE_LOADED } from "../store/mutations.names";
import GoogleMap from "../components/GoogleMap.vue";
import ConcernCarousel from "../components/CocernCarousel.vue";
import ContactForm from "../components/ContactForm.vue";
import RichText from "../components/RichText.vue";
import BasicHeader from "../components/BasicHeader.vue";
import axios from "axios";

const DEFAULT_APPLICATION_DATA: { [key: string]: any } = {
  job: null,
  full_name: "",
  email: "",
  phone: "",
  linkedin: "",
  message: "",
};

@Component({
  name: "JobPostPage",
  components: {
    rich_text: RichText,
    BasicHeader,
  },
})
export default class JobPostPage extends Vue {
  @Getter("pageData") public pageData!: pageDataType;
  @Getter("mainTimeLine") public masterTl: any;
  @Getter("mainTimeLineIsFinished") public mtlFinished!: boolean;
  @Mutation(ADD_ANIMATION) public [ADD_ANIMATION]: any;
  @Mutation(SET_PAGE_IMAGE_LOADED) public setPageImageLoaded: any;
  @Getter("isPageImageLoaded") public isPageImageLoaded!: boolean;
  public HOST: string = HOST;
  public addedAnimation: boolean = false;
  public controller = new ScrollMagic.Controller();
  public applicationData: { [key: string]: any } = JSON.parse(
    JSON.stringify(DEFAULT_APPLICATION_DATA)
  );
  public errors: any | null;
  public submitted: boolean = false;
  public $validator: any | null;
  @Watch("$route")
  public handleRouteChange(to: any, from: any) {
    // this.doPageLoad();
  }

  public mounted() {
    // this.doPageLoad();
    this.applicationData.job = this.pageData.id;
  }

  public resetForm() {
    this.applicationData = JSON.parse(JSON.stringify(DEFAULT_APPLICATION_DATA));
    this.applicationData.job = this.pageData.id;
    this.submitted = false;
  }

  public submitForm() {
    this.$validator.validate().then(() => {
      //  console.log(this.errors);

      if (this.errors.items.length == 0) {
        const data = JSON.parse(JSON.stringify(this.applicationData));
        data.job = this.pageData.id;
        const formData = new FormData();
        if (this.$refs.cv) {
          formData.append("cv", (this.$refs!.cv as any).files[0]);
          Object.keys(data).map((key: string) => {
            formData.append(key, data[key]);
          });
          axios
            .post(`${API_URL}/others/apply/`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              this.submitted = true;
            });
        }
      }
    });
  }

  public doPageLoad(el: any, done: any) {
    if (this.mtlFinished) {
      this.pageLoad();
    } else {
      this[ADD_ANIMATION](this.pageLoad, "-=0.65");
    }
    // this.addAnimation();
  }

  public addAnimation(): void {
    if (!this.addedAnimation && this.pageData.body != null) {
      const keys = this.pageData.body.map((item: any) => {
        return item.type;
      });
      const keySets = new Set(keys);
      for (const item of keySets) {
        const anim: any = animeDict[item as string] as any;
        if (anim) {
          anim().addTo(this.controller);
        }
      }
      this.addedAnimation = true;
    }
  }

  public pageLoad(): void {
    const fakeLoader =
      this.$refs.fakeloader || document.querySelector(".blank-div");
    const aboutImg =
      this.$refs.aboutimg ||
      document.querySelector(".about-img > picture > img");
    const pageloadTl = new TimelineMax({
      onComplete: () => {
        (fakeLoader as HTMLElement).removeAttribute("style");
      },
    });
    pageloadTl
      .set(fakeLoader, {
        height: "100%",
      })
      .to(fakeLoader, 1, {
        height: "20%",
        ease: Expo.easeInOut,
      })
      .set(fakeLoader, {
        height: "20%",
      })
      .to(
        fakeLoader,
        1,
        {
          left: "7.5%",
          right: "7.5%",
          transformOrigin: "center center",
          ease: Expo.easeOut,
        },
        "-=0.01"
      )
      .to(
        fakeLoader,
        1,
        {
          height: 0,
          ease: Power2.easeOut,
        },
        "-=0.45"
      )
      .fromTo(
        aboutImg,
        2.9,
        {
          scale: 1.2,
        },
        {
          scale: 1,
          // ease: SlowMo.ease.config(0.1, 0.1, false)
        },
        "-=2.6"
      )
      .staggerFromTo(
        ".short-title, .long-title, .desc",
        1,
        {
          yPercent: 70,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          ease: Expo.easeOut,
        },
        0.15,
        "-=1.1"
      );
  }
}
