





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConcernItem from './ConcernItem.vue';

@Component({
  name: 'ConcernCarousel',
  components: {
    ConcernItem,
  },
})
export default class ConcernCarousel extends Vue {
  @Prop() public compData: any | null;
  @Prop() public smController: any | null;
}
