var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageData)?_c('div',{staticClass:"template"},[_c('div',{staticClass:"overlay-loader"},[_c('div',{ref:"olay1",staticClass:"overlay-loader1"}),_c('div',{ref:"olay2",staticClass:"overlay-loader2"})]),_c('div',{ref:"section1",staticClass:"wrapper section"},[_c('div',{staticClass:"background-img"},[_c('picture',[_c('source',{attrs:{"media":"(max-width: 425px)","srcset":_vm.HOST +
            (_vm.pageData.promotion_show
              ? _vm.pageData.promotion_hero_image.meta.download_url
              : _vm.pageData.hero_image.mobile.src)}}),_c('source',{attrs:{"media":"(max-width: 1024px)","srcset":_vm.HOST +
            (_vm.pageData.promotion_show
              ? _vm.pageData.promotion_hero_image.meta.download_url
              : _vm.pageData.hero_image.tab.src)}}),_c('img',{attrs:{"src":_vm.HOST +
            (_vm.pageData.promotion_show
              ? _vm.pageData.promotion_hero_image.meta.download_url
              : _vm.pageData.hero_image.original.src),"alt":""},on:{"load":function($event){_vm.setPageImageLoaded(true)}}})])]),_c('transition',{attrs:{"css":false,"appear":""},on:{"enter":_vm.homeContent}},[(_vm.isPreloaderLoaded && _vm.isPageImageLoaded)?_c('div',{staticClass:"content"},[_c('div',{class:{
            'hero-text': true,
            'hero-text-promo': _vm.pageData.show_promotion,
          }},[_c('h3',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.pageData.promotion_show
                ? _vm.pageData.promotion_header
                : _vm.pageData.header)+"\n          ")]),_c('p',{staticClass:"sub-text"},[_vm._v("\n            "+_vm._s(_vm.pageData.promotion_show
                ? _vm.pageData.promotion_subtitle
                : _vm.pageData.subtitle)+"\n          ")])]),(_vm.pageData.promotion_show)?_c('a',{ref:"promBtn",staticClass:"promo-btn",attrs:{"href":_vm.pageData.promotion_link[0].value.html,"target":"_blank"}},[_c('p',[_vm._v("VIEW PROMOTION")])]):_vm._e(),_c('a',{ref:"scrollBtn",staticClass:"scroll-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.scrollAnim()}}},[_c('p',{staticClass:"scroll-text"},[_vm._v("SCROLL TO DISCOVER")]),_c('img',{staticClass:"arrow down-scroll",attrs:{"src":require("../assets/img/scroll-arrow.svg"),"alt":"Scroll Arrow"}})])]):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPageImageLoaded),expression:"isPageImageLoaded"}],ref:"section2",staticClass:"section section-two"},[_c('div',{ref:"slides",staticClass:"slider-track"},_vm._l((_vm.pageData.slider),function(slide,idx){return _c('div',{key:idx,class:{
          slide: true,
          'main-slider': true,
          'current-slide': _vm.slideIndex == idx,
        },style:({ left: _vm.setSlidePosition(idx) })},[_c('div',{staticClass:"slide-img"},[_c('picture',[_c('source',{attrs:{"media":"(max-width: 425px)","srcset":_vm.HOST + slide.value.image.mobile.src}}),_c('source',{attrs:{"media":"(max-width: 1024px)","srcset":_vm.HOST + slide.value.image.tab.src}}),_c('img',{staticClass:"slider-img",attrs:{"src":_vm.HOST + slide.value.image.original.src,"alt":slide.value.image.original.alt}})])]),_c('div',{staticClass:"slider"},[_c('div',{staticClass:"slider-content"},[_c('h2',[_vm._v("\n              "+_vm._s(slide.value.title
                  ? slide.value.title
                  : slide.value.page
                  ? slide.value.page.title
                  : "")+"\n            ")]),_c('p',[_vm._v(_vm._s(slide.value.text))]),_c('AppLink',{staticClass:"btn custom-btn",attrs:{"to":slide.value.link ||
                slide.value.page.html.replace(_vm.HOST, '') ||
                ''}},[_c('span',[_vm._v(_vm._s(slide.value.anchor))]),_c('img',{attrs:{"src":require("../assets/img/scroll-arrow.svg"),"alt":"Arrow"}})])],1)])])}),0),_c('div',{staticClass:"navigator",staticStyle:{"z-index":"99"}},_vm._l((_vm.pageData.slider),function(slide,idx){return _c('a',{key:'ctrl' + idx,class:{ 'navigator-link': true, 'link-active': _vm.slideIndex == idx },attrs:{"href":"#","data-slide":idx},on:{"click":function($event){$event.preventDefault();_vm.moveToSlide(_vm.slideIndex, idx)}}})}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }