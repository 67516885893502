


















































import { HOST, API_URL } from "../global";
import { State, Action, Getter, Mutation } from "vuex-class";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { pageDataType } from "../types";
import { TimelineMax, Expo, Power2, Power4 } from "gsap";
import ScrollMagic from "scrollmagic";
import ConcernImageAndText from "../components/ConcernImageAndText.vue";

import { animeDict } from "../animations";
import { ADD_ANIMATION, SET_PAGE_IMAGE_LOADED } from "../store/mutations.names";

@Component({
  name: "DetailsPage",
  components: {
    image_and_text: ConcernImageAndText,
  },
})
export default class DetailsPage extends Vue {
  @Getter("pageData") public pageData!: pageDataType;
  public HOST: string = HOST;
  @Mutation(ADD_ANIMATION) public [ADD_ANIMATION]: any;
  @Mutation(SET_PAGE_IMAGE_LOADED) public setPageImageLoaded: any;
  @Getter("isPageImageLoaded") public isPageImageLoaded!: boolean;
  @Mutation(ADD_ANIMATION) public [ADD_ANIMATION]: any;

  public addedAnimation: boolean = false;
  public controller = new ScrollMagic.Controller();

  public errors: any | null;
  public submitted: boolean = false;
  public $validator: any | null;
  @Watch("$route")
  public handleRouteChange(to: any, from: any) {
    // this.doPageLoad();
  }
  public animindus(): void {
    var animindustry = new TimelineMax({});
    animindustry
      .staggerFromTo(
        ".concern-header",
        1,
        {
          autoAlpha: 0,
          opacity: 0,
          //scale: .5,
          yPercent: -30,
        },
        {
          autoAlpha: 1,
          opacity: 1,
          delay: 0.9,
          yPercent: 0,
          // scale: 1,
          //force3D:true,
          ease: Power4.easeOut,
          clearProps: "all",
        },
        -0.01
      )
      .staggerFromTo(
        ".card",
        1,
        {
          autoAlpha: 0,
          opacity: 0,
          // scale: .5,
          yPercent: 30,
        },
        {
          autoAlpha: 1,
          opacity: 1,
          //scale: 1,
          //delay: .01,
          yPercent: 0,
          ease: Power4.easeOut,
          clearProps: "all",
        }
      );
  }
  public mounted() {
    this.$nextTick(() => {
      this.animindus();
    });
  }
}
