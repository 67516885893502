











































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { componentDataType } from "../types";
import { HOST } from "../global";
import { TimelineMax, Power4 } from "gsap";
import ScrollMagic from "scrollmagic";
import "ScrollMagicGSAP";

@Component({
  name: "FullWidthTextAndImage",
})
export default class FullWidthTextAndImage extends Vue {
  @Prop() public compData: any | null;
  @Prop() public smController: any | null;
  @Prop() public compId!: string;
  public HOST: string = HOST;
  public imageLoaded: boolean = false;

  public addAnimation(): void {
    const { overlay, pic } = this.$refs;
    const animateImgOverlay = new TimelineMax({ paused: true });
    animateImgOverlay.fromTo(
      overlay,
      2,
      {
        skewX: 30,
        scale: 1.7,
      },
      {
        skewX: 0,
        xPercent: 110,
        transformOrigin: "0% 100%",
        ease: Power4.easeOut,
      }
    );

    let scene = new ScrollMagic.Scene({
      triggerElement: pic,
      offset: 10,
      reverse: false,
      triggerHook: "onCenter",
    })
      .setTween(animateImgOverlay.progress(0).resume())
      .addTo(this.smController);
  }

  public mounted() {
    // this.$nextTick(() => {
    //   this.addAnimation();
    // });
  }
}
