import { render, staticRenderFns } from "./AwardList.vue?vue&type=template&id=4be99b72&scoped=true&"
import script from "./AwardList.vue?vue&type=script&lang=ts&"
export * from "./AwardList.vue?vue&type=script&lang=ts&"
import style0 from "./AwardList.vue?vue&type=style&index=0&id=4be99b72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be99b72",
  null
  
)

component.options.__file = "AwardList.vue"
export default component.exports