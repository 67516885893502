
























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { API_URL } from "../global";
import { TimelineMax, Expo } from "gsap";
import ScrollMagic from "scrollmagic";

const DEFAULT_FORM: { [key: string]: any } = {
  full_name: "",
  email: "",
  company: "",
  phone: "",
  message: "",
  subject: null,
};

@Component({
  name: "ContactForm",
})
export default class ContactForm extends Vue {
  @Prop() public compData!: string | null;
  @Prop() public smController!: any | null;
  public subjects: any | null = [];
  public errors!: any | null;
  public submitted: boolean = false;
  public $validator!: any | null;
  public submissionForm: { [key: string]: any } = JSON.parse(
    JSON.stringify(DEFAULT_FORM)
  );

  public addAnimation(): void {
    const { form } = this.$refs;
    const customRadios = (form as HTMLElement).querySelectorAll(
      ".custom-radio"
    );
    const formRows = (form as HTMLElement).querySelectorAll(".form-row");
    //console.log(this.smController, form, customRadios, formRows);
    const contactFormTl = new TimelineMax({ paused: true });
    contactFormTl
      .staggerFromTo(
        customRadios,
        1,
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          opacity: 1,
          yPercent: 0,
          ease: Expo.easeOut,
          clearProps: "all",
        },
        0.12
      )
      .fromTo(
        formRows,
        1,
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          ease: Expo.easeOut,
        },
        "-=0.9"
      );

    new ScrollMagic.Scene({
      triggerElement: ".contact-form",
      reverse: false,
      offset: 250,
      duration: "100%",
      triggerHook: "onEnter",
    })
      .setTween(contactFormTl.progress(0).resume())
      .addTo(this.smController);
  }

  public mounted() {
    this.getSubjects();
    this.$nextTick(() => {
      this.addAnimation();
    });
  }

  public resetForm() {
    this.submissionForm = JSON.parse(JSON.stringify(DEFAULT_FORM));
    this.submitted = false;
  }

  public submitForm() {
    this.$validator.validate().then(() => {
      // console.log(this.errors);
      if (this.errors.items.length == 0) {
        axios
          .post(`${API_URL}/others/contact/`, this.submissionForm)
          .then((resp) => {
            this.submitted = true;
          });
      }
    });
  }

  public getSubjects(): void {
    axios.get(`${API_URL}/others/contact-subjects/`).then(({ data }) => {
      this.subjects = data;
      this.$nextTick(() => {
        this.addAnimation();
      });
    });
  }
}
