








































import { Component, Vue, Prop } from "vue-property-decorator";
import { HOST } from "../global";

@Component({
  name: "AwardList",
})
export default class AwardList extends Vue {
  @Prop() public compData: any | null;
  @Prop() public smController: any | null;
  public HOST: string = HOST;
  public imageLoaded: boolean = false;
}
