






























import { State, Action, Getter, Mutation } from "vuex-class";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { pageDataType } from "../types";
import FullWidthTextAndImage from "../components/FullWidthTextAndImage.vue";
import YoutubeVideo from "../components/YoutubeVideo.vue";
import { HOST } from "../global";
import { animeDict } from "../animations";
import { TimelineMax, TimelineLite, Expo, Power2 } from "gsap";
import ScrollMagic from "scrollmagic";
import "ScrollMagicGSAP";
import { ADD_ANIMATION, SET_PAGE_IMAGE_LOADED } from "../store/mutations.names";
import GoogleMap from "../components/GoogleMap.vue";
import ConcernCarousel from "../components/CocernCarousel.vue";
import ContactForm from "../components/ContactForm.vue";
import BasicHeader from "../components/BasicHeader.vue";

@Component({
  name: "BasicPage",
  components: {
    full_width_text_and_image: FullWidthTextAndImage,
    youtube_video: YoutubeVideo,
    google_map: GoogleMap,
    concern_carousel: ConcernCarousel,
    contact_form: ContactForm,
    BasicHeader,
  },
})
export default class BasicPage extends Vue {
  @Getter("pageData") public pageData!: pageDataType;
  @Getter("mainTimeLine") public masterTl: any;
  @Getter("mainTimeLineIsFinished") public mtlFinished!: boolean;
  @Mutation(ADD_ANIMATION) public [ADD_ANIMATION]: any;
  @Getter("isPageImageLoaded") public isPageImageLoaded!: boolean;
  public HOST: string = HOST;
  public addedAnimation: boolean = false;
  public controller = new ScrollMagic.Controller();
  public pageLoaded: boolean = false;

  //public show: boolean = true;

  // public emmited(){
  //     console.log("emmited")
  // }

  @Watch("$route")
  public handleRouteChange(to: any, from: any) {
    //this.doPageLoad();
  }

  public mounted() {
    //this.doPageLoad();
  }
}
