






import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "ConcernText",
})
export default class ConcernText extends Vue {
  @Prop() public compData!: string | null;
}
