export const SET_CURRENT_PAGE = "storeCurrentPageData";
export const CURRENT_PAGE_ERROR = "currenPageError";
export const SET_INDUSTRIES_DATA = "setIndustriesData";
export const INDUSTRIES_DATA_ERROR = "industriesDataError";
export const SET_INDUSTRY_NEXT_ANIME = "setIndustryNextAnime";
export const SET_INDUSTRY_PREV_ANIME = "setIndustryPrevAnime";
export const SET_INDUSTRY_CURRENT_PAGE = "setIndustryCurrentPage";
export const SET_INDUSTRY_ANIMATION_LOCK = "setIndustryAnimationLock";
export const ADD_ANIMATION = "addMainTimeLineAnimation";
export const SET_MAIN_MENU = "setMainMenu";
export const SET_MAIN_MENU_ERROR = "setMainMenuError";
export const SET_ABOUT_MENU = "setAboutMenu";
export const SET_ABOUT_MENU_ERROR = "setAboutMenuError";
export const SET_JOB_POSTS = "setJobPost";
export const SET_JOB_POSTS_ERRORS = "errorJobPost";
export const SET_INDUSTRY_PARENT_PAGE = "setIndustryParentPage";
export const SET_MENU_LOADED = "setMenuLoaded";
export const SET_PRELOADER_LOADED = "setPreloaderLoaded";
export const SET_ANALYTICS_SETTINGS = "setAnalyticsSettings";
export const ANALYTICS_SETTINGS_ERROR = "analyticsSettingsError";
export const SET_PAGE_IMAGE_LOADED = "setPageImageLoaded";
