import { render, staticRenderFns } from "./ConcernImageAndText.vue?vue&type=template&id=0b20c140&scoped=true&"
import script from "./ConcernImageAndText.vue?vue&type=script&lang=ts&"
export * from "./ConcernImageAndText.vue?vue&type=script&lang=ts&"
import style0 from "./ConcernImageAndText.vue?vue&type=style&index=0&id=0b20c140&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b20c140",
  null
  
)

component.options.__file = "ConcernImageAndText.vue"
export default component.exports