















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'RichText',
})
export default class RichText extends Vue {
  @Prop() public compData: any | null;
}
